<template>
  <v-container fluid style="overflow:hidden">
    <v-card style="overflow:hidden">
      <v-toolbar>
        <v-toolbar-title>Class Calendar</v-toolbar-title>
        <v-spacer></v-spacer>
        <template v-if="isSmallScreen">
          <v-btn icon @click="showFilters = !showFilters">
            <v-icon>{{ showFilters ? 'fas fa-filter' : 'fal fa-filter' }}</v-icon>
          </v-btn>
        </template>
        <template v-else>
          <v-menu ref="dateMenuRef" v-model="dateMenu" :close-on-content-click="false" :return-value.sync="selectedDate" transition="scale-transition" offset-y min-width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="selectedDate" hide-details solo readonly v-bind="attrs" v-on="on" style="max-width:130px;margin-right:1em"></v-text-field>
            </template>
            <v-date-picker v-model="selectedDate" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="dateMenu = false">Cancel</v-btn>
              <v-btn text color="primary" @click="$refs.dateMenuRef.save(selectedDate)">OK</v-btn>
            </v-date-picker>
          </v-menu>
          <v-checkbox v-model="hideEmptyRooms" label="Hide Empty Rooms" hide-details solo style="margin-right:1em"></v-checkbox>
          <v-select v-model="selectedBuilding" :items="buildings" label="Building Filter" hide-details solo style="max-width:200px"></v-select>
        </template>
        <template v-if="isSmallScreen && showFilters" v-slot:extension>
          <v-menu ref="dateMenuRef" v-model="dateMenu" :close-on-content-click="false" :return-value.sync="selectedDate" transition="scale-transition" offset-y min-width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="selectedDate" hide-details solo readonly v-bind="attrs" v-on="on" style="max-width:130px;margin-right:1em"></v-text-field>
            </template>
            <v-date-picker v-model="selectedDate" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="dateMenu = false">Cancel</v-btn>
              <v-btn text color="primary" @click="$refs.dateMenuRef.save(selectedDate)">OK</v-btn>
            </v-date-picker>
          </v-menu>
          <v-checkbox v-model="hideEmptyRooms" label="Hide Empty Rooms" hide-details solo style="margin-right:1em"></v-checkbox>
          <v-select v-model="selectedBuilding" :items="buildings" label="Building Filter" hide-details solo style="max-width:200px"></v-select>
        </template>
      </v-toolbar>
      <v-dialog v-if="loading" :value="true" width="300">
        <v-card style="width:500px;margin:auto">
          <v-card-title>{{ loadingProgress >= 100 ? 'Building calendar...' : 'Loading classes...' }}</v-card-title>
          <v-card-text>
            <v-progress-linear :value="loadingProgress" background-color="blue lighten-3" color="blue darken-2"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <CalendarSchedule v-else :cell-width="80" :rows="filteredRooms" :start-hour="7" :end-hour="20" @click="openClassDetails"></CalendarSchedule>
    </v-card>
    <v-dialog v-model="dialogOpen" width="400">
      <v-card>
        <v-toolbar>
          <v-toolbar-title>Course Details</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogOpen = false">
            <v-icon>fal fa-times</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title style="word-break:normal">{{ selectedCourse.title ? selectedCourse.title.split(' - ')[0] : '' }}<br/>{{ selectedCourse.title ? selectedCourse.title.split(' - ')[1] : '' }}</v-card-title>
        <v-card-text>
          <h3>Instructor: {{ selectedCourse.instructor }}</h3>
          <v-list>
            <v-list-item v-for="(meeting, index) in selectedCourse.meetingBase" :key="'meetTime-'+index">
              <v-list-item-content>
                <v-list-item-title>{{ meeting.days }} {{ meeting.startTime }} - {{ meeting.endTime }}</v-list-item-title>
                <v-list-item-subtitle>{{ meeting.room }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<style>
div#schedule-container {
  overflow-x: scroll;
  overflow-y: auto;
  position: fixed;
}
div#schedule-container::-webkit-scrollbar {
  width: 5px;
  height: 11px;
}
div#schedule-container::-webkit-scrollbar-button {
  width: 5px;
  height: 0px;
}
div#schedule-container::-webkit-scrollbar-corner {
  background: transparent;
}
div#schedule-container::-webkit-scrollbar-thumb {
  background: linear-gradient(to top right, #193264, #003366);
  border: 0px solid transparent;
  border-radius: 50px;
}
div#schedule-container::-webkit-scrollbar-track {
  background: #091221;
  border: 0px none;
  border-radius: 53px;
}
div#currentTime {
  z-index: 10;
  height: 100%;
  position:absolute;
  width: 10px;
  border: 2px solid rgba(255, 165, 0, .5);
  border-width: 0 2px 0 0;
}
</style>
<script>
import { ref, reactive, computed, watch, onMounted } from '@vue/composition-api'

export default {
  components: {
    CalendarSchedule: () => import('@/components/calendar/schedule')
  },
  setup (props, { root }) {
    const isSmallScreen = computed(() => {
      switch (root.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm':
          return true
      }
      return false
    })
    const showFilters = ref(false)
    const service = root.$feathers.service('calendar/classes')
    const collapsed = ref(true)
    const buildings = ref([
      { value: '', text: 'All Buildings' },
      { value: 'BARNES', text: 'Barnes Physical Education Ctr' },
      { value: 'BROCK', text: 'Brock Hall' },
      { value: 'FINART', text: 'Chapel' },
      { value: 'JACKSN', text: 'Jackson Hall' },
      { value: 'KIRK', text: 'The Kirk' },
      { value: 'LUCAS', text: 'Lucas Art Workshop' },
      { value: 'MILLS', text: 'Mills Hall' },
      { value: 'SANDER', text: 'Sanderson Hall' }
    ])
    const selectedBuilding = ref('')
    const hideEmptyRooms = ref(false)
    const roomIndexes = reactive({})
    const rooms = ref([
      { bldg: 'ANDREA', code: 'AH108', name: 'Classroom', capacity: 40, type: 'C', classes: [] },
      { bldg: 'BARNES', code: 'BC312', name: 'Classroom', capacity: 40, type: 'C', classes: [] },
      { bldg: 'BROCK', code: 'BH114', name: 'Classroom', capacity: 35, type: 'C', classes: [] },
      { bldg: 'BROCK', code: 'BH118', name: 'Classroom', capacity: 38, type: 'C', classes: [] },
      { bldg: 'BROCK', code: 'BH120', name: 'Classroom', capacity: 38, type: 'C', classes: [] },
      { bldg: 'BROCK', code: 'BH122', name: 'Classroom', capacity: 38, type: 'C', classes: [] },
      { bldg: 'BROCK', code: 'BH2-TERR', name: 'Breezeway/Terrace', capacity: 99999, type: 'C', classes: [] },
      { bldg: 'BROCK', code: 'BH209', name: 'Language Lab', capacity: 18, type: 'C', classes: [] },
      { bldg: 'BROCK', code: 'BH212', name: 'Classroom', capacity: 25, type: 'C', classes: [] },
      { bldg: 'BROCK', code: 'BH214', name: 'Classroom', capacity: 27, type: 'C', classes: [] },
      { bldg: 'BROCK', code: 'BH313', name: 'Education Classroom', capacity: 20, type: 'C', classes: [] },
      { bldg: 'FINART', code: 'CL113', name: 'Classroom', capacity: 15, type: 'C', classes: [] },
      { bldg: 'FINART', code: 'CL122', name: 'Classroom', capacity: 60, type: 'C', classes: [] },
      { bldg: 'FINART', code: 'CL210', name: 'Chapel Auditorium', capacity: 768, type: 'C', classes: [] },
      { bldg: 'JACKSN', code: 'JH114', name: 'Digital Photo Studio', capacity: 42, type: 'C', classes: [] },
      { bldg: 'JACKSN', code: 'JH116', name: 'Art Studio with tables & chairs', capacity: 30, type: 'C', classes: [] },
      { bldg: 'JACKSN', code: 'JH117', name: 'Classroom--Digital Art Lab', capacity: 10, type: 'C', classes: [] },
      { bldg: 'KIRK', code: 'KI113', name: 'Charles W. Anderson Auditorium', capacity: 200, type: 'C', classes: [] },
      { bldg: 'LUCAS', code: 'LA103', name: '3D studio', capacity: 25, type: 'C', classes: [] },
      { bldg: 'LUCAS', code: 'LA109', name: '2D studio', capacity: 25, type: 'C', classes: [] },
      { bldg: 'MILLS', code: 'MH130', name: 'Classroom', capacity: 24, type: 'C', classes: [] },
      { bldg: 'MILLS', code: 'MH150', name: 'Computer Lab', capacity: 36, type: 'C', classes: [] },
      { bldg: 'MILLS', code: 'MH160', name: 'Classroom', capacity: 48, type: 'C', classes: [] },
      { bldg: 'MILLS', code: 'MH180', name: 'Classroom', capacity: 70, type: 'C', classes: [] },
      { bldg: 'MILLS', code: 'MH220', name: 'Biology Lab B', capacity: 32, type: 'C', classes: [] },
      { bldg: 'MILLS', code: 'MH232', name: 'Biology Lab C', capacity: 12, type: 'C', classes: [] },
      { bldg: 'MILLS', code: 'MH250', name: 'Biology Lab A', capacity: 40, type: 'C', classes: [] },
      { bldg: 'MILLS', code: 'MH270', name: 'Classroom', capacity: 42, type: 'C', classes: [] },
      { bldg: 'MILLS', code: 'MH280', name: 'Classroom', capacity: 55, type: 'C', classes: [] },
      { bldg: 'MILLS', code: 'MH310', name: 'Physics Lab', capacity: 32, type: 'C', classes: [] },
      { bldg: 'MILLS', code: 'MH320', name: 'Organic Chemistry Lab', capacity: 24, type: 'C', classes: [] },
      { bldg: 'MILLS', code: 'MH330', name: 'Physical Chemistry Lab', capacity: 8, type: 'C', classes: [] },
      { bldg: 'MILLS', code: 'MH350', name: 'General Chemistry Lab', capacity: 32, type: 'C', classes: [] },
      { bldg: 'MILLS', code: 'MH360', name: 'Physics Commons', capacity: 8, type: 'C', classes: [] },
      { bldg: 'MILLS', code: 'MH380', name: 'Classroom', capacity: 35, type: 'C', classes: [] },
      { bldg: 'SANDER', code: 'SN101', name: 'Classroom', capacity: 36, type: 'C', classes: [] },
      { bldg: 'SANDER', code: 'SN102', name: 'Classroom', capacity: 55, type: 'C', classes: [] },
      { bldg: 'SANDER', code: 'SN114', name: 'Classroom', capacity: 45, type: 'C', classes: [] },
      { bldg: 'SANDER', code: 'SN119B', name: 'Seminar/Classroom', capacity: 12, type: 'C', classes: [] },
      { bldg: 'SANDER', code: 'SN201', name: 'Classroom with tables & chairs', capacity: 32, type: 'C', classes: [] },
      { bldg: 'SANDER', code: 'SN202', name: 'Classroom', capacity: 55, type: 'C', classes: [] },
      { bldg: 'SANDER', code: 'SN215', name: 'Classroom/Auditorium', capacity: 190, type: 'C', classes: [] }
    ])
    const filteredRooms = computed(() => {
      let arr = rooms.value
      if (selectedBuilding.value !== '') {
        arr = arr.filter(({ bldg }) => bldg === selectedBuilding.value)
      }
      if (hideEmptyRooms.value) {
        arr = arr.filter(({ classes }) => classes.length > 0)
      }
      return arr.map(({ code, name, classes: events }) => { return { shortText: code, longText: code + ' ' + name, events } })
    })
    let dt = new Date()
    const selectedDate = ref(dt.toISOString().substr(0, 10))
    const dateMenu = ref(false)
    const dateMenuRef = ref(null)
    const classes = reactive({})
    const currentTimePosition = ref(0)

    setTimeout(() => {
      let now = new Date()
      let offset = (now.getHours() - 7) * 200 + (now.getMinutes() * 100 / 30)
      currentTimePosition.value = offset + (collapsed.value ? 100 : 300)
    }, 1000)

    const times = ref([])
    const loading = ref(false)
    const loadingProgress = ref(0)

    onMounted(() => {
      for (let i = 14; i <= 44; i++) {
        let hr = Math.floor((i / 2)) % 12
        let min = i % 2
        if (hr === 0) hr = 12
        let ampm = i < 24 ? 'am' : 'pm'
        times.value.push(hr + ':' + (min === 0 ? '00' : '30') + ampm)
      }

      for (let i = 0; i < rooms.value.length; i++) {
        roomIndexes[rooms.value[i].code] = i
      }

      loadClasses()
      setTimeout(() => {
        resizeCalendar()
      }, 100)
    })

    watch(selectedDate, () => {
      loadClasses()
    })

    function resizeCalendar () {
      if (scheduleContainerRef != null && 'value' in scheduleContainerRef && scheduleContainerRef.value != null) {
        scheduleContainerRef.value.style.width = scheduleContainerRef.value.parentNode.clientWidth + 'px'
        const bodyHeight = document.getElementsByTagName('body')[0].clientHeight
        let offsetTop = scheduleContainerRef.value.offsetTop
        if (offsetTop < 100) offsetTop += 100
        else offsetTop += 20
        scheduleContainerRef.value.style.height = (bodyHeight - offsetTop) + 'px'
      }
    }
    const sideNavActive = computed(() => root.$store.state.sideNavActive)
    watch(sideNavActive, (active) => { setTimeout(() => resizeCalendar(), active ? 200 : 120) })

    async function loadClasses () {
      loading.value = true
      rooms.value.forEach((room) => {
        room.classes = []
      })
      let first = new Date(selectedDate.value + ' 00:00:00')
      let next = new Date(selectedDate.value + ' 00:00:00')
      next.setDate(next.getDate() + 1)
      let query = {
        'meetings': { $elemMatch: { room: { $ne: null }, start: { $gt: first, $lt: next } } },
        $limit: 0
      }
      let { total } = await service.find({ query })
      query.$limit = 10
      let completed = 0
      for (let i = 0; i < total; i += 10) {
        query.$skip = i
        let { data } = await service.find({ query })
        for (const { _id, crn, title, instructors, meetingBase, meetings } of data) {
          // Go through the meetings array and find the one for this date
          meetings.forEach(({ room, start, end }) => {
            const st = new Date(start)
            let [startHour, startMin, startAmPm] = st.toLocaleTimeString().split(':')
            startHour = parseInt(startHour) + (startAmPm.substring(3) === 'PM' && startHour !== '12' ? 12 : 0)
            const et = new Date(end)
            let [endHour, endMin, endAmPm] = et.toLocaleTimeString().split(':')
            endHour = parseInt(endHour) + (endAmPm.substring(3) === 'PM' && endHour !== '12' ? 12 : 0)
            if (st < next && et > first && room != null) {
              if (!(room in roomIndexes)) {
                if (room.substr(0, 2) !== 'RL') console.log('Room not in roomIndexes: ' + room)
                return
              }
              let roomIndex = roomIndexes[room]
              let meetTimes = meetingBase.map(({ days, startTime, endTime }) => days + ' ' + startTime + '-' + endTime)
              rooms.value[roomIndex].classes.push({
                _id,
                crn: crn,
                start: startHour + ':' + startMin,
                end: endHour + ':' + endMin,
                title: title.replace(/ Section [0-9]*/, '').replace(' - ', '<br/>'),
                instructor: instructors.length > 0 ? instructors[0].name : '',
                meetTimes: meetTimes.join('; ')
              })
            }
          })
          completed++
          loadingProgress.value = Math.floor(completed / total * 100)
        }
      }
      setTimeout(() => { loading.value = false }, 200)
    }

    const scheduleContainerRef = ref(null)

    const dialogOpen = ref(false)
    const selectedCourse = reactive({})
    function openClassDetails (id) {
      service.get(id).then((data) => {
        selectedCourse.title = data.title
        selectedCourse.instructor = data.instructors.length > 0 ? data.instructors[0].name : ''
        selectedCourse.meetingBase = data.meetingBase
        dialogOpen.value = true
      })
    }

    return {
      isSmallScreen,
      showFilters,
      collapsed,
      currentTimePosition,
      times,
      loading,
      loadingProgress,
      buildings,
      selectedBuilding,
      hideEmptyRooms,
      rooms,
      roomIndexes,
      filteredRooms,
      selectedDate,
      dateMenu,
      dateMenuRef,
      classes,
      loadClasses,
      scheduleContainerRef,
      dialogOpen,
      selectedCourse,
      openClassDetails
    }
  }
}
</script>
